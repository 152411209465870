import React from "react";
import './menu.css';

export const menuItems = (history) => [
  {
    label: 'About',
    icon: require('../../assets/images/menu-icons/1BIANCA_Official_MENU-20530.jpg'),
    routePath: '/explore/about',
    onSelect: () => history.push('/explore/about')
  },
  {
    label: 'Music',
    icon: require('../../assets/images/menu-icons/2BIANCA_Official_MENU-.jpg'),
    routePath: '/explore/listen',
    onSelect: () => history.push('/explore/listen')
  },
  {
    label: 'Team',
    icon: require('../../assets/images/menu-icons/3BIANCA_Official_MENU-20533.jpg'),
    routePath: '/explore/team',
    onSelect: () => history.push('/explore/team')
  },
  {
    label: 'Gallery',
    icon: require('../../assets/images/menu-icons/4BIANCA_Official_MENU-20552.jpg'),
    routePath: '/explore/gallery',
    onSelect: () => history.push('/explore/gallery')
  },
  {
    label: 'Tour',
    icon: require('../../assets/images/menu-icons/5BIANCA_Official_MENU-20522.jpg'),
    routePath: '/explore/tour',
    onSelect: () => history.push('/explore/tour')
  },
  {
    label: 'Store',
    icon: require('../../assets/images/menu-icons/6BIANCA_Official_MENU-20537.jpg'),
    routePath: '/explore/store',
    onSelect: () => history.push('/explore/store')
  },
];

export const MenuSlider = React.memo(({menuOptions, currentRoute, height}) => {
  const menuIndex = menuOptions.findIndex((item) => item.routePath === currentRoute);

  return (
    <div
      className="menu-wrapper"
    >
      {menuOptions.map((option, i) =>
        <div
          onClick={() => option.onSelect()}
          style={{
          }}
          className={`${menuIndex === i ? 'center' : ''}`} key={i}>
          <span className={'menu-label button-glow'}>{option.label}</span>
        </div>
      )}
    </div>
  );
});
