/* eslint-disable */
import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import IconButton from "@material-ui/core/IconButton";

export const SVGFileButton = ({svgPath, svgURL, ...restProps}) =>
  <a href={svgURL} target='_blank'>
    <IconButton {...restProps}>
        <SvgIcon>
            <path d={svgPath}/>
        </SvgIcon>
    </IconButton>
  </a>
;

