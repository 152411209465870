
export const ACTION_TYPES = {
  GET_IMAGES: 'GET_IMAGES',
  GET_BOX_MUSIC: 'GET_BOX_MUSIC',
  GET_BAND_MUSIC: 'GET_BAND_MUSIC',
  SET_BOX_MUSIC: 'SET_BOX_MUSIC',
  SET_BAND_MUSIC: 'SET_BAND_MUSIC',
  SET_IMAGES: 'SET_IMAGES',
  LOGGED_IN: 'LOGGED_IN',
  SET_SCROLL_TOP: 'SET_SCROLL_TOP'
};

export const getImages = (imageGallery = '') => ({
  type: ACTION_TYPES.GET_IMAGES,
  payload: imageGallery
});

export const getMusic = (musicLib) => ({
  type: ACTION_TYPES[`GET_${musicLib}_MUSIC`],
});

export const setScrollTop = (fromTop) => ({
  type: ACTION_TYPES.SET_SCROLL_TOP,
  payload: fromTop
});

export const setLoggedIn = () => ({
  type: ACTION_TYPES.LOGGED_IN,
});
