import {ACTION_TYPES} from "../actions";


export const musicState = (state = { boxPlaylist: [], bandPlaylist: [] }, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_BOX_MUSIC:
      return { ...state, boxPlaylist: action.payload };
      case ACTION_TYPES.SET_BAND_MUSIC:
      return { ...state, bandPlaylist: action.payload };
    default:
      return state;
  }
};
