import React from 'react';
import {ParallaxBanner, ParallaxProvider} from "react-scroll-parallax";
import { Button, TextField } from '@material-ui/core';
import './updated-page.css'

const handleUserSubscribe = (handleSubmit) => (event) => {
  event.preventDefault();
  const fields = Array.from(event.target.querySelectorAll('input'));
  const formValue = fields.reduce((acc, next) => ({...acc, [next.id]: next.value}), {});
  handleSubmit(formValue);
  debugger;
};

export const PublicPage = ({signUp}) => <ParallaxProvider>

  <div className="top-banner">
    <div className="top-signature" />
  </div>

  <div className="second-banner-container">

    <ParallaxBanner
      className="banner-2"
      layers={[
        {
          image: require('../../../assets/images/updated-page/2ABOUTSPIN_PINKSPACEORGANISM_BIANCAOFFICIAL.gif'),
          amount: 0.17,
        },
        {
          image: require('../../../assets/images/updated-page/2ABOUTBIANCAOFFICIAL_FOREGROUNDIMAGE_ABOUT.png'),
          amount: 0.1,

        },
      ]}
      style={{
        height: '100vh',
      }}
    >
      <div className="banner-2-text">
        <h2>ABOUT</h2>
        Bianca is a Swiss American singer, songwriter, keyboardist and producer, currently working in Toronto and LA. She blends pop, R&B, and melodic synth style that lends to a future-pop sound. Her work has also been described as alternative pop, urban soul, and dream R&B.
      </div>
    </ParallaxBanner>
  </div>

  <div className="third-banner-container">
    <div className="third-banner-signature" />

    <ParallaxBanner
      className="banner-3"
      layers={[
        {
          image: require('../../../assets/images/updated-page/BIANCADIVIDERSIGNATURE.png'),
          amount: 0.17,
        }
      ]}
      style={{
        height: '100vh',
      }}
    >
    </ParallaxBanner>
  </div>

  <div className="fourth-banner-container">
    <div className="banner-4-text">
      <p>Spring 2020 dates TBA</p>
      {/*<p><span>Jan 01 20</span><span>Montreal QC</span></p>*/}
      {/*<p><span>Jan 01 20</span><span>Outer Space</span></p>*/}
      {/*<p><span>Jan 01 20</span><span>Outer Space</span></p>*/}
      {/*<p><span>Jan 01 20</span><span>Outer Space</span></p>*/}
    </div>
  </div>

  <div className="fifth-banner-container">
    <div className="sign-up-container">
      <span className="sign-up-text">Sign Up</span>
      <form className="sign-up-form" onSubmit={handleUserSubscribe(signUp)}>
        <TextField id="name" variant="outlined" label="Name" required />
        <TextField id="email" variant="outlined" label="Email" required />
        <TextField id="planet" variant="outlined" label="Planet" />
        <div><Button type="submit" className="submit-btn">Submit</Button></div>
      </form>

    </div>
  </div>

  {/*<Parallax y={[-20, 20]} tagOuter="figure">*/}
  {/*  <img src={require('../../../assets/images/updated-page/1HOMEBIANCAOFFICIAL_HOME.jpg')}  alt=""/>*/}
  {/*</Parallax>*/}
</ParallaxProvider>;
