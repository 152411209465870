import {applyMiddleware, combineReducers, createStore} from "redux";
import {galleryState} from "./reducers/gallery.reducer";
import logger from 'redux-logger';
import {epicMiddleware} from "./epics";
import {getBandMusic, getBoxMusic, getGalleryImagesEpic} from "./epics/gallery.epics";
import {listenToScrollEpic} from "./epics/dom.epic";
import {combineEpics} from "redux-observable";
import {miscState} from "./reducers/misc.reducer";
import {musicState} from "./reducers/music.reducer";

export const configStore = () => {
  const store = createStore(
    combineReducers({
      gallery: galleryState,
      misc: miscState,
      music: musicState,
    }),
    applyMiddleware(
      logger,
      epicMiddleware
    )
  );

  epicMiddleware.run(combineEpics(getGalleryImagesEpic, getBoxMusic, getBandMusic, listenToScrollEpic));
  return store;
};
