import {ACTION_TYPES} from "../actions";


export const galleryState = (state = { galleryImages: [] }, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_IMAGES:
      return { ...state, galleryImages: action.payload };
    default:
      return state;
  }
};
