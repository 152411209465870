import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './App';
import registerServiceWorker from './registerServiceWorker';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import {Provider} from "react-redux";

import {configStore} from "./redux/redux.config";

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
  typography: {
    useNextVariants: true,
  },
});


ReactDOM.render(<MuiThemeProvider theme={theme}>
  <Provider store={configStore()}>
    <App />
  </Provider>
</MuiThemeProvider>, document.getElementById('root'));
registerServiceWorker();
