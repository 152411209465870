import {fromEvent} from "rxjs";
import {map, distinctUntilChanged, debounceTime} from "rxjs/operators";
import {setScrollTop} from "../actions";

export const listenToScrollEpic = (actions$, state, {window}) =>
  fromEvent(window, 'scroll')
    .pipe(
      map(event => event.target.defaultView.scrollY),
      distinctUntilChanged((prev, curr) => Math.abs(prev - curr) < 75),
      debounceTime(300),
      map(setScrollTop)
    );
