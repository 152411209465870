import React from 'react';
import Typography from '@material-ui/core/Typography';
import './glitch-text.css';

export const GlitchText = ({children, variant = "h2", altText, style, className}) => {
  return <Typography className={`glitch-text ${className}`}
                     color="textSecondary"
                     data-text={altText || children}
                     variant={variant}
                     style={style}
  >
      {children}
  </Typography>
};
