import {ACTION_TYPES} from "../actions";
import {map, mergeMap, toArray, switchMap} from "rxjs/operators";
import {forkJoin, from} from "rxjs";

const shuffle = arr => arr.filter(Boolean).sort(() => Math.random() - 0.5);


export const getGalleryImagesEpic = (actions$, state, {fbApp}) =>
  actions$.ofType(ACTION_TYPES.GET_IMAGES)
    .pipe(
      mergeMap(() => fbApp.storage().ref('gallery').listAll()),
      switchMap(galleryRef =>
        from(galleryRef.prefixes).pipe(
          mergeMap(subGallery => subGallery.listAll()),
          mergeMap(gallery =>
            from(gallery.items).pipe(
              mergeMap(gallImage => gallImage.getDownloadURL()),
              toArray(),
              map(galleryImageUrls =>{
                return ({
                  imageUrls: shuffle(galleryImageUrls),
                  name: gallery.items[0].parent.name.split('_').map(fragment => `${fragment.charAt(0)}${fragment.slice(1)}`).join(' ')
                })
              }),
              toArray()
            )
          ),
          toArray()
        ),
      ),
      map(payload => ({type: ACTION_TYPES.SET_IMAGES, payload}))
    );


export const getBoxMusic = (actions$, state, { fbApp }) =>
  actions$.ofType(ACTION_TYPES.GET_BOX_MUSIC)
    .pipe(
      mergeMap(() => fbApp.storage().ref(`music/BOX`).listAll()),
      switchMap(list =>
        {
          return forkJoin(list.items.map(x => x.getDownloadURL())
        )
        },
      ),
      map(payload => ({type: ACTION_TYPES.SET_BOX_MUSIC, payload}))
    );

export const getBandMusic = (actions$, state, { fbApp }) =>
  actions$.ofType(ACTION_TYPES.GET_BAND_MUSIC)
    .pipe(
      mergeMap(() => fbApp.storage().ref(`music/BAND`).listAll()),
      switchMap(list =>
        {
          return forkJoin(list.items.map(x => x.getDownloadURL())
          )
        },
      ),
      map(payload => ({type: ACTION_TYPES.SET_BAND_MUSIC, payload}))
    );
