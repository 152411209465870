import {ACTION_TYPES} from "../actions";


export const miscState = (state = { fromTop: window ? window.scrollY : 0, loggedIn: false }, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_SCROLL_TOP:
      return { ...state, fromTop: action.payload };
    case ACTION_TYPES.LOGGED_IN:
      return { ...state, loggedIn: true };
    default:
      return state;
  }
};
