import React from 'react';
// import './App.css';
import {Route, BrowserRouter as Router, Switch, withRouter} from "react-router-dom";
import {menuItems, MenuSlider} from "./components/menu/menu";
import AppBar from "@material-ui/core/AppBar";
import {ICON_SVG_PATHS} from "./constants";
import {SVGFileButton} from "./components/svg-file-button/svg-file-button";
import {connect} from "react-redux";
import {GlitchText} from "./components/glitch-text/glitch-text";
import {firebaseApp, postData} from "./redux/firebase.config";
import {setLoggedIn} from "./redux/actions";
import {PublicPage} from "./components/routes/public/public";


const signIn = (onLogin) => (username, password) => {
  postData('https://us-central1-bianca-c3473.cloudfunctions.net/auth', {username, password})
    .then(({token}) => firebaseApp.auth().signInWithCustomToken(token))
    .then(onLogin);
};

const signUp = (userData) => {
  debugger;
  postData('https://us-central1-bianca-c3473.cloudfunctions.net/signUp', userData);
};


const isHome = ({pathname}) => pathname === '/';

const AppHeader = withRouter(({location, fromTop}) => {
  return <AppBar className="header-container"
                 style={{
                   backgroundColor: `rgba(0, 0, 0,  ${fromTop > 40 ? 1 : fromTop / 3000}`,
                   alignItems: 'center',
                   ...(isHome(location) ? {boxShadow: 'none'} : {})
                 }}
                 position={isHome(location) ? 'absolute' : 'sticky'}
  >

    <GlitchText className="main-header">BIANCA</GlitchText>
    <Route component={({location, history}) => <MenuSlider menuOptions={menuItems(history)}
                                                           currentRoute={location.pathname}/>} path={'/explore'}/>
  </AppBar>
});

export const App = connect(
  ({misc: {fromTop, loggedIn}}, oldProps) => ({fromTop, loggedIn, ...oldProps}),
  {setIsLoggedIn: setLoggedIn})(({fromTop, setIsLoggedIn, ...restProps}) => {
  return <Router>
    <div className="main">
      {/*<AppHeader fromTop={fromTop}/>*/}
      <Switch>
        {/*<Route component={(routeProps) => <HomePage {...routeProps} {...restProps} signInWithEmailAndPassword={signIn(setIsLoggedIn)} />} path={'/'} exact={true}/>*/}
        <Route component={(routeProps) => <PublicPage {...routeProps} {...restProps} signUp={signUp} />} path={'/'} exact={true}/>
        {/*<Route component={Explore} path={'/explore'}/>*/}
        <Route component={() => <h1>404 - Page Not Found</h1>}/>
      </Switch>

      <footer className="icon-row">
        {Object.values(ICON_SVG_PATHS).map(path => <SVGFileButton key={path} svgPath={path.svgPath} svgURL={path.svgURL}/>)}
      </footer>
    </div>
  </Router>;
});
