import * as firebase from 'firebase';
export const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyDOrPX_xvwgp707PAvZWlEyAFKgELYjLEU",
    authDomain: "biancaofficial.com",
    databaseURL: "https://bianca-c3473.firebaseio.com",
    projectId: "bianca-c3473",
    storageBucket: "bianca-c3473.appspot.com",
    messagingSenderId: "513519318389",
    appId: "1:513519318389:web:14fab6928888864d"
});


export function postData(url = '', data = {}) {
  // Default options are marked with *
  return fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then(response => response.json()); // parses JSON response into native JavaScript objects
}
